<template>
  <div>
    <el-row :gutter="20" class="item-row">
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ (homeData.current_revenue || 0) | unitPrice }}</span>
              <div>总营收(元)</div>
            </div>
            <img src="/img/dd-y@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #34cdbd, #129b95);"
          >
            <span>本月订单总数</span>
            <span>{{ homeData.current_order_sum || 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ (homeData.current_refund_price || 0) | unitPrice }}</span>
              <div>总退款(元)</div>
            </div>
            <img src="/img/tk-y@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #fe8058, #f94d4b);"
          >
            <span>本月退款</span>
            <span>{{ homeData.current_refund_sum || 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ (homeData.revenue || 0) | unitPrice }}</span>
              <div>总营收(元)</div>
            </div>
            <img src="/img/dd-z@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #73abff, #2079ff);"
          >
            <span>累计订单总数</span>
            <span>{{ homeData.order_sum || 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ (homeData.refund_price || 0) | unitPrice }}</span>
              <div>总退款(元)</div>
            </div>
            <img src="/img/tk-z@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #f3a433, #fe8d24);"
          >
            <span>累计退款</span>
            <span>{{ homeData.refund_sum || 0 }}笔</span>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="elcard">
          <div slot="header" class="clearfix">
            <span>待处理事务</span>
          </div>
          <div class="item" @click="toActiveOrOrder('active',0)">
            <span>活动上架待审批</span>
            <span>({{ activeWaitAudit }})</span>
          </div>
          <div class="item" @click="toActiveOrOrder('order','REFUNDING')">
            <span>待退款笔数</span>
            <span>({{ orderWaitRefunded }})</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="elcard">
          <div slot="header" class="clearfix">
            <span>平台公告</span>
          </div>
          <div class="item" @click="toPlatformInstructions('0')">
            <span>商户须知</span>
          </div>
          <div class="item" @click="toPlatformInstructions('1')">
            <span>常见问题</span>
          </div>
          <div class="item" @click="toPlatformInstructions('2')">
            <span>活动发布说明</span>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>订单统计</p>
            <p>
              <span :class="active==='lastday'?'active':'no-active'" @click="handleTime(1)">昨日</span>
              <span :class="active==='day'?'active':'no-active'" @click="handleTime(0)">今日</span>
              <span :class="active==='week'?'active':'no-active'" @click="handleTime(7)">近7天</span>
              <span :class="active==='mouth'?'active':'no-active'" @click="handleTime(30)">近30天</span>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px;margin-left: 10px;margin-right:10px;"
                v-model="times"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{disabledDate(time) { return time.getTime() > Date.now() + 86400000 }}"
                @change="orderTimeChange"
              ></el-date-picker>
              <el-select
                style="width:140px"
                v-model="orderLeftForm.order_status"
                clearable
                placeholder="请选择订单状态"
                @change="dashboardOrderRight"
              >
                <el-option value label="全部"></el-option>
                <el-option value="PAID_OFF" label="待参加"></el-option>
                <el-option value="IN" label="进行中"></el-option>
                <el-option value="COMPLETE" label="已完成"></el-option>
              </el-select>
            </p>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{ orderLeftList.order_sum }}</h2>
                <p v-if="active === 'day' || active === 'lastday' ">
                  {{ active === 'day' ? '今' : active === 'lastday' ? '昨' : '' }}天订单总数(笔)
                </p>
                <p v-else>
                  近{{
                    active === 'week' ? "7" : active === 'mouth' ? '30' : ""
                  }}天订单总数(笔)</p>
              </div>
              <div class="echarts-left">
                <h2>{{ orderLeftList.revenue | unitPrice("￥") }}</h2>
                <p v-if="active === 'day' || active === 'lastday' ">
                  {{ active === 'day' ? '今' : active === 'lastday' ? '昨' : '' }}天订单总额
                </p>
                <p v-else>
                  近{{
                    active === 'week' ? "7" : active === 'mouth' ? '30' : ""
                  }}天订单总额</p>
              </div>
              <div class="echarts-left">
                <h2>{{ orderLeftList.net_revenue | unitPrice("￥") }}</h2>
                <p v-if="active === 'day' || active === 'lastday' ">
                  {{ active === 'day' ? '今' : active === 'lastday' ? '昨' : '' }}天净收入金额
                </p>
                <p v-else>
                  近{{
                    active === 'week' ? "7" : active === 'mouth' ? '30' : ""
                  }}天净收入金额</p>
              </div>
            </div>
            <div :span="20" id="echarts_right" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>退款订单统计</p>
            <p>
              <span
                :class="refundActive==='lastday'?'active':'no-active'"
                @click="refundHandleTime(1)"
              >昨日</span>
              <span
                :class="refundActive==='day'?'active':'no-active'"
                @click="refundHandleTime(0)"
              >今日</span>
              <span
                :class="refundActive==='week'?'active':'no-active'"
                @click="refundHandleTime(7)"
              >近7天</span>
              <span
                :class="refundActive==='mouth'?'active':'no-active'"
                @click="refundHandleTime(30)"
              >近30天</span>
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 250px;margin-left: 10px;margin-right:10px;"
                v-model="refundTimes"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="{disabledDate(time) { return time.getTime() > Date.now() + 86400000 }}"
                @change="refundTimeChange"
              ></el-date-picker>
            </p>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{ refundLeftList.refund_sum }}</h2>
                <p v-if="refundActive === 'day' || refundActive === 'lastday'">
                  {{ refundActive === 'day' ? '今' : refundActive === 'lastday' ? '昨' : '' }}天退款订单总数(笔)
                </p>
                <p v-else>
                  近{{
                    refundActive === 'week' ? "7" : refundActive === 'mouth' ? '30' : ""
                  }}天退款订单总数(笔)</p>
              </div>
              <div class="echarts-left">
                <h2>{{ refundLeftList.refund_price | unitPrice("￥") }}</h2>
                <p v-if="refundActive === 'day' || refundActive === 'lastday'">
                  {{ refundActive === 'day' ? '今' : refundActive === 'lastday' ? '昨' : '' }}天退款订单总额
                </p>
                <p v-else>
                  近{{
                    refundActive === 'week' ? "7" : refundActive === 'mouth' ? '30' : ""
                  }}天退款订单总额</p>
              </div>
            </div>
            <div :span="20" id="echarts_right_refund" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_orderList from "@/api/orderList";
import * as API_activityList from "@/api/activityList";
import * as API_Home from "@/api/home";
import {$$router} from "@/libs/router/router";

export default {
  name: "home",
  components: {
  },
  data() {
    return {
      //  列表loading状态
      loading: false,
      goodsList: null,
      memberList: null,
      orderLeftForm: {
        start_time: "",
        end_time: "",
        order_status: "",
        cycle_type: "TIME",
        seller_id: "",
      },
      refundLeftForm: {
        start: "",
        end: "",
        cycle_type: "TIME",
        seller_id: "",
      },
      orderLeftList: {},
      refundLeftList: {
        current_refund_price: 0,
        refund_price: 0
      },
      active: "day",
      refundActive: "day",
      times: [],
      refundTimes: [],
      orderAll: {
        sum: 0
      },
      orderMonth: {
        sum: 0
      },
      activeWaitAudit: 0,
      orderWaitRefunded: 0,
      homeData: {},
      // 判断用户信息是否完善
      userPrefect: true
    };
  },
  mounted() {
    this.$store.dispatch('getShopInfoAction');

    const loading = this.$loading({text: '加载中'});

    setTimeout(() => {
      loading.close();

      this.getHome_data();

      // 初始化图表
      this.$nextTick(() => {
        this.sesalChart = this.$echarts.init(
          document.getElementById("echarts_right")
        );
      });
      this.$nextTick(() => {
        this.sesalChartRefund = this.$echarts.init(
          document.getElementById("echarts_right_refund")
        );
      });

      // 获取本月订单数和订单金额
      this.GET_tradeOrders("month");

      // 获取全部订单数和订单金额
      this.GET_tradeOrders("all");

      // 获取待退款笔数
      this.GET_tradeOrders("waitRefunded");

      // 获取待审核活动
      this.POST_activeGoodsActivityPage();

      // 获取shop_id
      this.orderLeftForm.seller_id = this.$store.getters.shopInfo.shop_id;
      this.refundLeftForm.seller_id = this.$store.getters.shopInfo.shop_id;

      // 获取今天的订单统计
      this.handleTime(0);

      // 获取今天的退款订单统计
      this.refundHandleTime(0);
    }, 2000);
  },
  activated() {
    // 初始化图表
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
    });
    this.$nextTick(() => {
      this.sesalChartRefund = this.$echarts.init(
        document.getElementById("echarts_right_refund")
      );
    });

    // 获取本月订单数和订单金额
    this.GET_tradeOrders("month");

    // 获取全部订单数和订单金额
    this.GET_tradeOrders("all");

    // 获取待退款笔数
    this.GET_tradeOrders("waitRefunded");

    // 获取待审核活动
    this.POST_activeGoodsActivityPage();

    // 获取今天的订单统计
    this.handleTime(0);

    // 获取今天的退款订单统计
    this.refundHandleTime(0);
  },
  methods: {
    // shopPrefect() {
    //   API_Home.shopPrefect().then(res => {
    //     this.userPrefect = res
    //   }).then(() => {
    //     if (!this.userPrefect) {
    //       this.$confirm('店铺信息未完善, 前去完善?', '提示', {
    //         confirmButtonText: '确定',
    //         type: 'warning'
    //       }).then(() => {
    //         this.$router.push({
    //           path: '/shop/shop-base-info'
    //         })
    //       }).catch(() => {
    //         location.reload();
    //       })
    //     }
    //   })
    // },

    /** 订单图表时间选择 */
    handleTime(has) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime());
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      if (has === 1) {
        start.setTime(start.getTime() - 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        end.setTime(start.getTime())
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        this.active = "lastday";
      } else if (has === 7) {
        start.setTime(start.getTime() - 6 * 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        this.active = "week";
      } else if (has === 30) {
        start.setTime(start.getTime() - 29 * 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        this.active = "mouth";
      } else if (has === 0) {
        // end.setTime(start.getTime() + 3600 * 1000 * 24);
        this.active = "day";
      }
      this.orderLeftForm.start_time = parseInt(start.getTime() / 1000);
      this.orderLeftForm.end_time = parseInt(end.getTime() / 1000);
      this.times = [];
      this.times.push(start.getTime());
      this.times.push(end.getTime() - 1000);
      this.dashboardOrderLeft();
      this.dashboardOrderRight();
    },

    /** 退款订单图表时间选择 */
    refundHandleTime(has) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime());
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      if (has === 1) {
        start.setTime(start.getTime() - 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        end.setTime(start.getTime())
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        this.refundActive = "lastday";
      } else if (has === 7) {
        start.setTime(start.getTime() - 6 * 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        this.refundActive = "week";
      } else if (has === 30) {
        start.setTime(start.getTime() - 29 * 24 * 3600 * 1000)
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        this.refundActive = "mouth";
      } else if (has === 0) {
        // end.setTime(start.getTime() + 3600 * 1000 * 24);
        this.refundActive = "day";
      }
      this.refundLeftForm.start = parseInt(start.getTime() / 1000);
      this.refundLeftForm.end = parseInt(end.getTime() / 1000);
      this.refundTimes = [];
      this.refundTimes.push(start.getTime());
      this.refundTimes.push(end.getTime() - 1000);
      this.dashboardRefundLeft();
      this.dashboardRefundRight();
    },
    getHome_data() {
      API_Home.getHome_date().then((res) => {
        this.homeData = res;
        console.log('homeData', res);
      });
    },
    /** 获取图表数据 */
    Get_list(xAxis, orderNum, orderPrice, netRevenue) {
      this.loading = false;
      this.sesalChart.setOption({
        backgroundColor: "#fff", // 背景色
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,
        },
        yAxis: {
          name: "订单金额/￥元",
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        legend: {
          orient: "vertical",
          x: "right",
          y: "top",
          data: ["订单数", "订单总额", "订单净收入数据"],
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            name: "订单数",
            data: orderNum,
            type: "line",
            smooth: true, // 让曲线变平滑的
            symbol: "none", // 去掉点
          },
          {
            name: "订单总额",
            data: orderPrice,
            type: "line",
            smooth: true, // 让曲线变平滑的
            symbol: "none", // 去掉点
          },
          {
            name: "订单净收入数据",
            data: netRevenue,
            type: "line",
            smooth: true, // 让曲线变平滑的
            symbol: "none", // 去掉点
          },
        ],
      });
    },

    /** 获取退款图表数据 */
    Get_list_refund(xAxis, refundNum, refundPrice) {
      this.loading = false;
      this.sesalChartRefund.setOption({
        backgroundColor: "#fff", // 背景色
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,
        },
        yAxis: {
          name: "订单金额/￥元",
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        legend: {
          orient: "vertical",
          x: "right",
          y: "top",
          data: ["退款订单数", "退款订单总额"],
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            name: "退款订单数",
            data: refundNum,
            type: "line",
            smooth: true, // 让曲线变平滑的
            symbol: "none", // 去掉点
          },
          {
            name: "退款订单总额",
            data: refundPrice,
            type: "line",
            smooth: true, // 让曲线变平滑的
            symbol: "none", // 去掉点
          },
        ],
      });
    },

    /** 获取订单列表 */
    GET_tradeOrders(type) {
      var params = {};
      params.page_no = 1;
      params.page_size = 10;
      if (type === "month") {
        var dateLow = new Date();
        dateLow.setDate(1);
        dateLow.setHours(0);
        dateLow.setMinutes(0);
        dateLow.setSeconds(0);
        dateLow = Date.parse(dateLow) / 1000;

        var dateHigh = new Date();
        dateHigh.setMonth(dateHigh.getMonth() + 1);
        dateHigh.setDate(1);
        dateHigh.setHours(0);
        dateHigh.setMinutes(0);
        dateHigh.setSeconds(0);
        dateHigh = Date.parse(dateHigh) / 1000;

        params.start_time = dateLow;
        params.end_time = dateHigh;
      }
      if (type === "waitRefunded") {
        params.order_status = "REFUNDING";
      }
      API_orderList.tradeOrders(params).then((res) => {
        if (type === "month") {
          this.orderMonth = res;
          if (this.orderMonth.sum == null) {
            this.orderMonth.sum = 0;
          }
        } else if (type === "all") {
          this.orderAll = res;
          if (this.orderAll.sum == null) {
            this.orderAll.sum = 0;
          }
        } else if (type === "waitRefunded") {
          this.orderWaitRefunded = res.data_total;
        }
      });
    },

    /** 获取活动列表 */
    POST_activeGoodsActivityPage() {
      var activeParams = {
        page_no: 1,
        page_size: 10,
        is_auth: 0
      };
      API_activityList.activeGoodsActivityPage(activeParams).then((res) => {
        this.activeWaitAudit = res.data_total;
      });
    },

    /** 订单时间改变 */
    orderTimeChange(val) {
      console.log(val);
      this.orderLeftForm.start_time = "";
      this.orderLeftForm.end_time = "";
      this.active = "";
      this.times = [];
      if (val) {
        this.times.push(val[0]);
        this.times.push(val[1]);
        this.orderLeftForm.start_time = parseInt(val[0] / 1000);
        this.orderLeftForm.end_time = parseInt(val[1] / 1000);
        this.dashboardOrderRight();
      }
    },

    /** 退款订单时间改变 */
    refundTimeChange(val) {
      console.log(val);
      this.refundLeftForm.start = "";
      this.refundLeftForm.end = "";
      this.refundActive = "";
      this.refundTimes = [];
      if (val) {
        this.refundTimes.push(val[0]);
        this.refundTimes.push(val[1]);
        this.refundLeftForm.start = parseInt(val[0] / 1000);
        this.refundLeftForm.end = parseInt(val[1] / 1000);
        this.dashboardRefundRight();
      }
    },

    /** 订单统计左侧数据 */
    dashboardOrderLeft() {
      API_Home.dashboardOrderLeft(this.orderLeftForm).then((res) => {
        this.orderLeftList = res;
        console.log(res);
      });
    },

    /** 订单统计右侧数据 */
    dashboardOrderRight() {
      var orderRightForm = this.orderLeftForm;
      orderRightForm.start = this.orderLeftForm.start_time;
      orderRightForm.end = this.orderLeftForm.end_time;
      API_Home.dashboardOrderRight(orderRightForm).then((res) => {
        var orderNum = [];
        var orderPrice = [];
        var netRevenue = [];
        res.series.data.forEach((item) => {
          item = JSON.parse(item);
          orderNum.push(item.orderNum);
          orderPrice.push(item.orderPrice);
          netRevenue.push(item.netRevenue);
        });
        this.Get_list(res.xAxis, orderNum, orderPrice, netRevenue);
        console.log(res);
      });
    },

    /** 退款订单统计左侧数据 */
    dashboardRefundLeft() {
      API_Home.dashboardRefundLeft(this.refundLeftForm).then((res) => {
        this.refundLeftList = res;
        console.log(res);
      });
    },

    /** 退款订单统计右侧数据 */
    dashboardRefundRight() {
      API_Home.dashboardRefundRight(this.refundLeftForm).then((res) => {
        var refundNum = [];
        var refundPrice = [];
        res.series.data.forEach((item) => {
          item = JSON.parse(item);
          refundNum.push(item.refundNum);
          refundPrice.push(item.refundPrice);
        });
        this.Get_list_refund(res.xAxis, refundNum, refundPrice);
        console.log(res);
      });
    },

    /** 跳转到说明与须知页面 */
    toPlatformInstructions(type) {
      $$router.get().forward("说明与须知", {
        type: type,
      });
    },

    /** 跳转到活动列表或订单列表页面 */
    toActiveOrOrder(type, name) {
      if (type === "active") {
        $$router.get().forward("活动列表", {
          type: name,
        });
      } else if (type === "order") {
        $$router.get().forward("订单列表", {
          type: name,
        });
      }
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;

  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  position: relative;

  .topBox {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .textBox {
      span {
        color: #212121;
        font-size: 28px;
        font-weight: 400;
      }

      div {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
      }
    }

    img {
      width: 60px;
    }
  }

  .bottomBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: yellow;
    width: 100%;
    padding: 8px 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 12px;

    span {
      color: #fff;
      font-size: 14px;
    }
  }
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.echart-title::after {
  display: none;
}

.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }

  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}

.echarts-left1 {
  width: 15%;
}

.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }

  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}

.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}

.echarts-right {
  width: 80%;
  height: 350px;
}

.el-row {
  position: relative;
  margin-bottom: 20px;
}
</style>
